<template>
  <div class="useradmin">
    <a-table :columns="columns" :data-source="tablelist">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'address2'">
          <a-select
            ref="select"
            v-model:value="record.address2"
            style="width: 120px"
          >
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="2">否</a-select-option>
          </a-select>
        </template>
        <template v-if="column.key === 'address3'">
          <a-select
            ref="select"
            v-model:value="record.address3"
            style="width: 120px"
          >
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="2">否</a-select-option>
          </a-select>
        </template>
        <template v-if="column.key === 'address4'">
          <a-select
            ref="select"
            v-model:value="record.address4"
            style="width: 120px"
          >
            <a-select-option value="1">是</a-select-option>
            <a-select-option value="2">否</a-select-option>
          </a-select>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
export default {
  setup() {
    const state = reactive({
      tablelist: [
        {
          key: "1",
          name: "000001",
          age: 32,
          address: "无",
          address2: "2",
          address3: "2",
          address4: "2",
        },
        {
          key: "2",
          name: "0000002",
          age: 42,
          address: "无",
          address2: "2",
          address3: "2",
          address4: "2",
        },
      ],
      columns: [
        {
          title: "uid",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "昵称",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "是否有封面",
          dataIndex: "address",
          key: "address2",
        },
        {
          title: "是否vup列表展示",
          dataIndex: "address",
          key: "address3",
        },
        {
          title: "轮播展示",
          dataIndex: "address",
          key: "address4",
        },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped></style>
