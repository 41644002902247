<template>
  <div class="tbcover">
    <a-form
      :model="formState"
      name="validate_other"
      v-bind="formItemLayout"
      @finishFailed="onFinishFailed"
      @finish="onFinish"
    >
      <a-form-item
        label="uid"
        name="username"
        :rules="[{ required: true, message: '请输入uid' }]"
      >
        <a-input v-model:value="formState.username" />
      </a-form-item>
      <a-form-item name="imageUrl" label="选择图片">
        <a-upload
          v-model:file-list="fileList"
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <loading-outlined v-if="loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 2, offset: 2 }">
        <a-button type="primary" html-type="submit">确定</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { reactive, ref } from "vue";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  setup() {
    const formItemLayout = {
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 16,
      },
    };
    const formState = reactive({
      username: "",
    });
    const fileList = ref([]);
    const loading = ref(false);
    const imageUrl = ref("");

    function handleChange(info) {
      if (info.file.status === "uploading") {
        loading.value = true;
        return;
      }

      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (base64Url) => {
          imageUrl.value = base64Url;
          loading.value = false;
        });
      }

      if (info.file.status === "error") {
        loading.value = false;
        message.error("上传失败");
      }
    }

    function beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";

      if (!isJpgOrPng) {
        message.error("图片格式错误");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        message.error("图片大于2MB!");
      }

      return isJpgOrPng && isLt2M;
    }

    function onFinish(values) {
      console.log("Success:", values);
    }

    function onFinishFailed(errorInfo) {
      console.log("Failed:", errorInfo);
    }

    return {
      formState,
      onFinish,
      onFinishFailed,
      formItemLayout,
      fileList,
      loading,
      imageUrl,
      handleChange,
      beforeUpload,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ant-upload {
  width: 100% !important;
  height: 200px;
}
::v-deep .ant-form-item-label > label {
  color: white;
}
.ant-upload {
  span {
    img {
      transform: scale(0.5);
    }
  }
}
.tbcover {
}
</style>
