<template>
  <div class="addvup">
    <a-form
      :model="formState"
      name="basic"
      autocomplete="off"
      v-bind="formItemLayout"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <!-- :label-col="{ span: 8 }" -->
      <a-form-item
        label="uid"
        name="uid"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input v-model:value="formState.uid" />
      </a-form-item>

      <a-form-item :wrapper-col="{ span: 2, offset: 2 }">
        <a-button type="primary" html-type="submit">添加</a-button>
      </a-form-item>
    </a-form>

    <a-textarea v-model:value="value2" placeholder="批量添加" allow-clear />

    <a-button type="primary" @click="addsomevup">确定添加</a-button>
  </div>
</template>
<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { addvup } from "../../../api/vup/vup";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
export default defineComponent({
  setup() {
    const formItemLayout = {
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 16,
      },
    };
    const store = useStore();
    const userinfo = computed(() => store.state.Login.userinfo);
    const formState = reactive({
      uid: "",
      num: 0,
    });
    const value2 = ref("");
    async function onFinish() {
      let { code } = await addvup({
        midId: formState.uid,
        userId: userinfo.value.id,
      });
      if (code === 200) {
        message.success("添加成功");
      }
    }

    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    async function addsomevup() {
      JSON.parse(value2.value).list.map(async (item) => {
        formState.num++;
        let { code } = await addvup({
          midId: item.uid,
          userId: userinfo.value.id,
        });
        if (code === 200) {
          message.success("添加成功");
        }
      });
    }
    return {
      value2,
      formItemLayout,
      formState,
      onFinish,
      onFinishFailed,
      addsomevup,
    };
  },
});
</script>
<style lang="scss" scoped>
::v-deep .ant-form-item-required {
  color: white;
}
.addvup {
  color: white;
}
</style>
