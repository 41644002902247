<template>
  <div class="suggest animated fadeIn">
    <!-- background: '#f78fad', -->
    <a-tabs
      v-model:activeKey="activeKey"
      :tab-position="'left'"
      :style="{ height: '80vh' }"
      :tabBarStyle="{
        color: 'white',
      }"
      @tabScroll="callback"
    >
      <a-tab-pane key="1" tab="同步vup封面">
        <Tbcover />
      </a-tab-pane>
      <a-tab-pane key="2" tab="添加vup">
        <Addvup />
      </a-tab-pane>
      <a-tab-pane key="3" tab="人员管理">
        <UseradminVue />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { ref } from "vue";
import Tbcover from "./page/tbcover.vue";
import Addvup from "./page/addvup.vue";
import UseradminVue from "./page/useradmin.vue";
export default {
  components: {
    Tbcover,
    Addvup,
    UseradminVue,
  },
  setup() {
    const activeKey = ref("1");

    return {
      activeKey,
    };
  },
};
</script>
<style lang="scss" scoped>
.suggest {
  height: 100%;
  width: 100%;
  color: white;
}
</style>
